import React, {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import ajaxRequest from '../../assets/functions/ajaxRequest.js'
import checkMobileBrowser from '../../assets/functions/checkMobileBrowser'

import useImageUploads from '../../hooks/useImageUploads'

import Context from './context.jsx'

import LoadingSpinner from '../shared/utility/loadingSpinner.jsx'
import CameraIcon from '../shared/icons/cameraIcon.jsx'
import PhotoIcon from '../shared/icons/photoIcon.jsx'

export default function RedeemForm({offer}) {
  const {
    consumer,
    brand,
    getConsumer,
    authenticityToken,
    selectedState,
    history,
    buttonTextColor,
  } = useContext(Context)

  const {files, imageUrls, handleFileInputChange, removeFile} = useImageUploads(
    {maxImages: 6},
  )
  const [submissionInProgress, setSubmissionInProgress] = useState(false)

  const atLeastOneFile = Object.values(files).length

  function submitMediaUpload(event) {
    event.preventDefault()
    setSubmissionInProgress(true)

    const formData = new FormData()

    formData.append('media_upload[offer_id]', offer.id)
    formData.append(
      'media_upload[state_abbreviation]',
      selectedState.abbreviation,
    )

    Object.values(files).forEach((image, index) => {
      formData.append(
        `media_upload[media_upload_images_attributes][${index}][image]`,
        image,
        image.name,
      )
    })

    ajaxRequest('POST', '/media_uploads', {
      headers: [{'X-CSRF-Token': authenticityToken}],
      body: formData,
    })
      .then((response) => {
        getConsumer()
        history.push('/balance')
      })
      .catch((response) => console.log(response))
  }

  return (
    <div className='mx-auto mb-8 p-4 border border-light'>
      {consumer.phone_verified_at ? (
        <>
          <h3 className='mb-1 text-xl md:text-2xl text-center text-dark uppercase font-bold'>
            Upload your receipt
          </h3>
          <p className='mb-1 text-sm text-center'>
            A full itemized receipt, including location, date, and transaction
            number is required
          </p>
          <p className='mb-4 text-sm text-center'>
            Circle or highlight the qualifying products on your receipt to get
            your rebate faster
          </p>

          <form onSubmit={submitMediaUpload} className='mx-auto'>
            <div className='flex flex-wrap gap-2 justify-center mb-2'>
              <input
                className='hidden'
                onChange={handleFileInputChange}
                type='file'
                accept='image/*'
                name='image'
                id='gallery'
                multiple
              />

              <label
                htmlFor='gallery'
                className='inline-block py-2 px-6 border-2 border-dark text-left text-lg md:text-xl uppercase font-bold align-middle whitespace-nowrap cursor-pointer transition duration-300 hover:bg-dark hover:text-white'>
                <PhotoIcon extraClasses='mr-1' />
                Gallery
              </label>

              {checkMobileBrowser() ? (
                <>
                  <input
                    className='hidden'
                    onChange={handleFileInputChange}
                    type='file'
                    accept='image/*'
                    id='camera'
                    capture='camera'
                  />

                  <label
                    htmlFor='camera'
                    className='inline-block py-2 px-6 border-2 border-dark text-left text-lg md:text-xl uppercase font-bold align-middle whitespace-nowrap cursor-pointer transition duration-300 hover:bg-dark hover:text-white'>
                    <CameraIcon extraClasses='mr-1' />
                    Camera
                  </label>
                </>
              ) : (
                <></>
              )}
            </div>

            <div className='flex flex-wrap gap-2 justify-center'>
              {Object.values(files).map((file) => {
                return (
                  <img
                    key={`uploadedImagePreview_${file.name}`}
                    src={imageUrls[file.name]}
                    className='w-32 h-32 object-cover object-center cursor-pointer'
                    title='Remove'
                    onClick={(event) => {
                      event.preventDefault()
                      removeFile(file)
                    }}
                  />
                )
              })}
            </div>

            {submissionInProgress ? (
              <LoadingSpinner
                size='12'
                color='text-primary'
                extraClasses='mx-auto'
                wrapperClasses='mt-2'
              />
            ) : (
              <button
                disabled={submissionInProgress}
                className={`block w-full sm:w-64 mt-2 mx-auto p-2 rounded-full transition duration-300 text-center ${buttonTextColor} hover:shadow ${
                  atLeastOneFile
                    ? 'bg-primary'
                    : 'pointer-events-none bg-gray-100'
                }`}>
                Submit
              </button>
            )}
          </form>
        </>
      ) : (
        <>
          <h3 className='mb-1 text-xl md:text-2xl text-center text-dark uppercase font-bold'>
            Verify your phone number
          </h3>
          <p className='mb-1 text-sm text-center'>
            You must verify your phone number before you can redeem this offer
          </p>

          <Link
            to='/account'
            className={`block w-full sm:w-64 mt-2 mx-auto p-2 rounded-full transition duration-300 bg-primary text-center ${buttonTextColor} hover:shadow`}>
            Verify Phone Number
          </Link>
        </>
      )}
    </div>
  )
}
