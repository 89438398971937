import {useState} from 'react';



export default function UseImageUploads(config) {
    const maxImages = config && config.maxImages ? config.maxImages : 10;

    const [files, setFiles] = useState({});
    const [imageUrls, setImageUrls] = useState({});

    function handleFileInputChange(event) {
        event.persist();

        const maxNewFiles = maxImages - Object.values(files).length;
        
        const newFiles = Array.prototype.slice.call(event.target.files);
        const limitedNewFiles = newFiles.slice(0, maxNewFiles);

        const newFilesObject = {};
        
        event.target.value = '';
        
        limitedNewFiles.forEach( newFile => newFilesObject[newFile.name] = newFile );

        if (limitedNewFiles.length) setFiles( files => ({...files, ...newFilesObject}) );

        updateImageUrls(limitedNewFiles);
    }

    function updateImageUrls(newFiles) {
        const promises = [];

        newFiles.forEach( newFile => {
            const reader = new FileReader();

            promises.push( new Promise((resolve, reject) => {
                reader.onload = function(event) {
                    resolve({name: newFile.name, url: event.target.result});
                };
            }) );

            reader.readAsDataURL(newFile);
        } );

        Promise.all(promises).then(newImageUrls => {
            const newImageUrlsObject = newImageUrls.reduce((newImageUrlsObject, imageUrl) => {
                newImageUrlsObject[imageUrl.name] = imageUrl.url;
                return newImageUrlsObject;
            }, {});
    
            setImageUrls( imageUrls => ({...imageUrls, ...newImageUrlsObject}) );
        });
    }

    function removeFile(file) {
        setImageUrls( imageUrls => {
            let temp = {...imageUrls};
            delete temp[file.name];
            return temp;
        } );

        setFiles( files => {
            let temp = {...files};
            delete temp[file.name];
            return temp;
        } );
    }

    return {files, imageUrls, handleFileInputChange, removeFile};
}